var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agreement-box" },
    [
      _c("i-header", {
        attrs: { title: "隐私声明" },
        on: { "back-event": _vm.toBack },
      }),
      _c(
        "div",
        { staticClass: "cnt-box" },
        [
          _c("div", {
            staticClass: "cnt",
            domProps: { innerHTML: _vm._s(_vm.cnt) },
          }),
          _c(
            "div",
            { staticClass: "check-box" },
            [
              _c(
                "i-checkbox",
                {
                  attrs: { "checked-color": "var(--themeColor)" },
                  model: {
                    value: _vm.checked,
                    callback: function ($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked",
                  },
                },
                [_vm._v("在此勾选，确认您已充分阅读、理解并同意上述内容。")]
              ),
            ],
            1
          ),
          _c(
            "i-button",
            {
              attrs: {
                disabled: !_vm.checked || _vm.disabled,
                round: "",
                loading: _vm.loading,
                "loading-text": "同意",
                color: "var(--themeColor)",
                block: "",
              },
              on: { click: _vm.toAgree },
            },
            [_vm._v("同意")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }