<template>
    <div class="agreement-box">
        <i-header title="隐私声明" @back-event="toBack"></i-header>
        <div class="cnt-box">
            <div class="cnt" v-html="cnt"></div>
            <div class="check-box">
                <i-checkbox v-model="checked" checked-color="var(--themeColor)">在此勾选，确认您已充分阅读、理解并同意上述内容。</i-checkbox>
            </div>
            <i-button :disabled="!checked || disabled" round
                      :loading="loading" @click="toAgree"
                      loading-text="同意"
                      color="var(--themeColor)" block>同意</i-button>
        </div>
    </div>
</template>

<script>
import { httpService } from "@/services/httpService.js";
let apiUrs = {
    query: "/api/engine/event/Disclaimer/GetMyLastUnread",
    read: "/api/engine/event/Disclaimer/Read"
};

export default {
    async beforeRouteEnter(to, from, next) {

        let result = await httpService.common(apiUrs.query,{
            DisclaimerType: 1
        });
        if (result.success && !result.content) {
            next("/")
        } else {
            // 往路由地址中添加参数
            next((vm) => {
                vm.cnt = result.content.content;
                vm.disclaimerId = result.content.disclaimerId;
            });
        }
    },
    name: "Agreement",
    data() {
        return {
            checked: false,
            loading: false,
            cnt: '',
            disclaimerId: '',
            disabled: false,
        }
    },
    created() {
    },
    methods: {
        toBack() {
        },
        async toAgree() {
            this.loadingBtn = true;
            var res = await httpService.common(apiUrs.read, { content: this.content, disclaimerId: this.disclaimerId });
            if (res && res.success) {
                this.toHomePage()
            }
            this.loadingBtn = false
        },
        toHomePage() {
            this.$router.push({
                path: '/'
            })
        }
    }
}
</script>

<style lang="less" scoped>
.agreement-box {
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF;
    .cnt-box {
        height: calc(100vh - 0.44rem);
        overflow-y: scroll;
        padding: 0 0.18rem 0.3rem 0.18rem;
        box-sizing: border-box;
        .check-box {
            margin: 0.27rem 0;
        }
    }
}
</style>
